.shopSearchPlace {
  width: 100%;
  height: 50px;
}

.shopSearch {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: transparent;
  overflow: hidden;

  @media all and (min-width: 700px) {
    max-width: 700px;

    &::after {
      content: "";
      position: fixed;
      z-index: -1;
      width: 100vw;
      height: 100vh;
      background-color: var(--white);
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: all ease 0.2s;
      pointer-events: none;
    }
  }

  &.open {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--lighter-gray);
    overflow: hidden;
    padding: calc(env(safe-area-inset-top, 20px) + 20px) 0 env(safe-area-inset-bottom, 20px);
    transition: all ease 0.3s;
    will-change: top, left, width, height, background-color;
    z-index: 3606;

    @media all and (min-width: 700px) {
      max-width: 700px;
      left: 50% !important;
      transform: translateX(-50%);

      &::after {
        opacity: 1;
      }
    }
  }
}
