.inputWrapper {
  position: relative;
  width: 100%;
  height: 50px;
  min-height: 50px;
  color: var(--black);
  transition: all ease 0.2s;

  &.open {
    padding: 0 10px;

    .closeButton {
      transform: translateX(0);
      opacity: 1;
      pointer-events: auto;
    }

    .searchIcon {
      transform: translateX(20%);
      opacity: 0;
    }

    .searchInput {
      background-color: var(--white);
    }
  }
}

.closeButton,
.searchIcon {
  font-family: inherit;
  border: none;
  background: none;
  outline: none;
  text-align: unset;
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-20%);
  opacity: 0;
  transition: all ease 0.2s;
  pointer-events: none;
}

.closeButton {
  left: 10px;
}

.searchIcon {
  transform: none;
  opacity: 1;
  pointer-events: none;
}

.searchInput {
  width: 100%;
  height: 100%;
  // border-radius: 15px; // TODO: Should be 15, but for some reason on iOS when scrolling down, full round corners are done. So this workaround avoids the switch

  border-radius: 999px;
  background-color: var(--white);
  color: var(--black);
  border: none;
  transition: all ease 0.2s;
  padding: 0 10px 0 50px;
  font-family: inherit;
  font-size: 1rem;
  appearance: none;

  &:focus {
    outline: none;
  }

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}
