.shopFeaturedProducts {
  width: 100%;
  position: relative;
  z-index: 2;

  h2 {
    color: var(--gradientText);
    font-size: 1.1rem;
    padding: 0;
    margin-left: 1.5rem;
    text-transform: uppercase;
    &.cat {
      margin-top: 1rem;
      color: var(--dark);
    }
  }

  .productList {
    margin-top: -0.5rem;
    width: 100%;
    overflow: scroll;
    display: flex;
    white-space: nowrap;
    padding: 0 10px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .lastSpace {
      width: 10px;
      min-width: 10px;
    }

    .productItem {
      border: none;
      text-align: left;
      font-family: inherit;
      position: relative;
      width: 150px;
      min-width: 150px;
      padding: 0.25rem;
      margin: 0.25rem;
      // padding-bottom: 3rem;
      background-color: var(--white);
      color: var(--black);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      outline: none;

      .imgWrapper {
        overflow: hidden;
        border-radius: 15px;
        width: calc(150px - 0.5rem);
        height: calc(150px - 0.5rem);
        margin-right: 0.5rem;
        object-fit: cover;
        border-radius: calc(20px - 0.3rem);
        background: white;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          //opacity: 0.3;
          padding: 10px;

          * {
            fill: var(--light);
          }
        }
      }

      .name {
        width: 100%;
        font-size: 1.2rem;
        padding: 0.5rem 0.5rem 0rem 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // .desc {
      //   width: 100%;
      //   opacity: 0.7;
      //   padding: 0 0.5rem;
      //   font-size: 0.8rem;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }

      .price {
        margin-left: auto;
        padding: 0 0.5rem 0.5rem 0.5rem;
        font-size: 1.2rem;
        // position: absolute;
        // bottom: 0.5rem;
        // right: 0.5rem;
      }
      .upperSmall {
        font-size: 0.7rem;
        opacity: 0.5;
        padding-bottom: 0;
        margin-right: 0.2rem;
        text-transform: uppercase;
      }
      .strikePrice {
        position: absolute;
        background: var(--gradient);
        font-weight: bold;
        color: var(--gradientText);
        padding: 0.15rem 0.5rem;
        border-radius: 5px;
        right: 0.75rem;
        top: 0.75rem;
        text-align: center;
        font-size: 1rem;
        box-shadow: var(--boxShadow);
        -webkit-transform: rotate(12deg);
        -moz-transform: rotate(12deg);
        -ms-transform: rotate(12deg);
        -o-transform: rotate(12deg);
        transform: rotate(12deg);
        span {
          display: block;
          color: var(--gradientText);
          // text-decoration: line-through;
          font-size: 0.8rem;
          line-height: 0.8rem;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            right: 0;
            border-top: 1px solid;
            border-color: var(--gradientText);
            opacity: 0.9;
            padding: 0;

            -webkit-transform: rotate(-12deg);
            -moz-transform: rotate(-12deg);
            -ms-transform: rotate(-12deg);
            -o-transform: rotate(-12deg);
            transform: rotate(-12deg);
          }
        }
      }
    }
  }
}
