button.switchButton {
  border: none;
  position: relative;
  min-width: 50px;
  min-height: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 10px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 20px;
  color: black;
  text-transform: uppercase;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  outline: none;
  flex-shrink: 0;
  flex-grow: 1;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
  }

  svg {
    min-width: 18px;
  }

  span {
    margin-left: 10px;
    padding-right: 10px;
    font-size: 1rem;
    font-weight: bold;
  }
}
