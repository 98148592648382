@keyframes basketAppear {
  0% {
    opacity: 0;
    transform: translate(-50%, 30px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.shopStickyBasket {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 50%;
  color: var(--navColor);
  transition: all ease 0.3s;
  width: 100%;
  max-width: 700px;

  opacity: 1;
  transform: translate(-50%, 0);
  pointer-events: auto;
  animation: basketAppear 0.3s linear;

  button {
    background: none;
    border: none;
    margin: 0;
    font-family: inherit;
    color: inherit;
    text-align: inherit;

    &:focus {
      outline: none;
    }
  }

  h2 {
    color: var(--dark);
    letter-spacing: 0.01em;
  }

  .basketInner {
    padding: 10px 10px calc((env(safe-area-inset-bottom, 20px) * 1.5) + 10px);
    min-height: 50px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .customBasketInfo {
    font-size: 1rem;
    line-height: 1.2;
    margin: 1rem 0;
  }
  .taxInfo {
    font-size: 0.85rem;
    line-height: 1;
    opacity: 0.8;
    margin-left: auto;
    margin-block: 0.5rem;
    text-align: right;
  }

  .navInner {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: flex-end;
    //transition: all ease 0.1s;
  }

  &Overlay {
    position: fixed;
    z-index: 9;
    background: var(--navOverlay);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: all ease 0.3s;
    backdrop-filter: blur(10px);
    border: none;

    &:focus {
      outline: none;
    }

    &.expand {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--navBG);
    opacity: 1;
    backdrop-filter: blur(5px);
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 10px rgba(black, 0.3);
  }

  .pickupButton {
    width: 100%;
    height: 50px;
    font-size: 1rem;
    background-color: var(--light);
    color: var(--black);
    padding: 0 1rem;
    margin: 0.5rem 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cartPreview {
    flex: 1;
    position: relative;
    background: none;
    border: none;
    text-align: left;
    color: inherit;
    padding: 0;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      height: 100%;
      width: 50px;
      pointer-events: none;
      background: linear-gradient(-90deg, var(--navBG), var(--whiteTransparent));
    }

    ul {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        padding: 0;
        margin: 0 -25px 0 0;
        opacity: 0;
        transform: translateX(-10px);
        animation: fadein 0.3s 0.4s ease forwards;
        font-size: 1rem;
        display: flex;
        align-items: center;

        .comments {
          font-size: 1rem;
          padding: 0.5rem;

          span {
            display: block;
            font-size: 0.8rem;
            opacity: 0.5;
            margin-bottom: 2px;
            line-height: 1;
          }

          p {
            line-height: 1.2;
            font-size: 1rem;
            margin: 0;
          }
        }

        .selectedOptionsList {
          padding: 0.5rem;

          .item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.2rem 0;
            border-bottom: 1px solid var(--light);

            &.basePrice {
              text-transform: uppercase;
              font-size: 0.8rem;
              font-weight: bold;
              opacity: 0.5;
              border-bottom-color: var(--dark);
            }

            &.totalPrice {
              font-weight: bold;
              border-bottom: none;
              color: var(--secondary);
              border-top: 1px solid var(--secondary);
            }
          }
        }

        .itemWrapper {
          position: relative;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          font-size: 1rem;
        }

        .imgWrapper {
          width: 50px;
          height: 50px;
          margin: 0;
          position: relative;
          overflow: hidden;

          .count {
            position: absolute;
            top: 0;
            left: 0;
            margin: 2px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 7px;
            background-color: var(--navBG);
            font-size: 0.7rem;
            font-weight: bold;
          }

          img,
          .imgPlaceholder {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            border: 2px solid var(--navBG);
            background: var(--light);
            object-fit: cover;
          }

          .bundleIcon {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            border: 2px solid var(--navBG);
            background: var(--light);
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .meta {
          position: relative;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          padding: 0;
          flex: 1;
          overflow: hidden;
          opacity: 0;
          transform: translateX(-10px);
          flex-direction: column;
          transition: all ease 0.3s;

          .titleRow {
            width: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .selectedOptions {
            font-size: 0.8rem;
            opacity: 0.5;
            padding-right: 1rem;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 0.9rem;
            line-height: 1;
            margin-top: 2px;
            transition: all ease 0.2s;

            svg {
              opacity: 0.7;
              margin-top: -2px;
            }
          }

          .name {
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;
            line-height: 1;
          }

          .price {
            font-weight: bold;
            padding-right: 10px;
            line-height: 1;
          }
        }

        .actionWrapper {
          display: flex;
          justify-content: space-between;
          padding: 1rem 0.2rem 0.2rem;

          .deleteButton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: var(--light);
            color: red;
            border-radius: 12px;
            padding: 0;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }

        &.editItem {
          .selectedOptions {
            height: 0;
            margin-top: 0;
          }
        }
      }
    }
  }

  &.hidden {
    display: none;
  }

  &.expand {
    .basketInner {
      padding: 10px 10px calc((env(safe-area-inset-bottom, 10px) * 1.5) + 10px);
    }

    .navInner {
      max-height: calc(80vh - 70px);
      flex-direction: column;
      align-items: flex-start;
    }

    .cartPreview {
      flex: 1;
      width: 100%;
      overflow: auto;
      margin-bottom: -15px;
      padding-bottom: 15px;

      &::after {
        display: none;
      }

      ul {
        flex-direction: column;
        margin: 5px 0 10px;

        li {
          width: 100%;
          position: relative;
          margin: 0 0 5px;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          padding: 0;
          border-radius: 15px;
          border: 1px solid var(--light);

          .imgWrapper {
            img,
            .imgPlaceholder {
              border-radius: 15px;
              border-width: 0.2rem;
            }
          }

          img {
            margin-right: 10px;
            border-width: 0;
          }

          .meta {
            opacity: 1;
            transform: translateX(0);
            width: 100%;
            padding: 0 10px;
          }
        }
      }
    }

    button.cartButton {
      width: 100%;
    }
  }

  .basketButtonWrapper {
    display: flex;

    .backButton {
      min-width: 50px;
      margin-right: 0.5rem;
      border-radius: 10px;
      background-color: var(--light);
      color: var(-black);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .personalView {
    display: flex;
    flex-direction: column;
    padding: 20px 20px calc(env(safe-area-inset-bottom, 20px) + 20px);

    form {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      input {
        width: 100%;
        height: 50px;
        font-family: inherit;
        font-size: 1rem;
        margin-bottom: 5px;
        background-color: var(--white);
        border: none;
        border-radius: 15px;
        padding: 0 15px;
      }

      .footerRow {
        display: flex;
        margin-top: 10px;

        button {
          position: relative;
          background: var(--light);
          color: var(--black);
          min-width: 50px;
          height: 50px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          font-weight: bold;
          text-transform: uppercase;
          margin: 0;
          z-index: 2;

          &[type="submit"] {
            z-index: 1;
            flex: 1;
            background: var(--gradient);
            color: var(--gradientText);
            margin-left: 15px;
          }
        }
      }
    }
  }

  .shopQuantity {
    width: auto;
    display: flex;
    padding: 2px;
    border-radius: 10px;
    background-color: var(--light);
    min-width: 120px;

    button {
      flex: 0 0 30px;
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.7;
      &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }

      svg {
        pointer-events: none;
      }
    }

    input {
      width: 0;
      flex: 1;
      margin: 0;
      border: none;
      background: var(--white);
      color: var(--black);
      border-radius: 10px;
      text-align: center;
      font-family: inherit;
      font-size: 1rem;

      &:focus {
        outline: none;
      }
    }
  }
}

button.cartButton {
  border: none;
  position: relative;
  min-width: 50px;
  min-height: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 20px;
  background: var(--gradient);
  color: var(--gradientText);
  text-transform: uppercase;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  outline: none;
  flex-shrink: 0;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
  }

  svg {
    min-width: 18px;
  }

  span {
    margin-left: 10px;
    padding-right: 10px;
    font-size: 1rem;
    font-weight: bold;

    &.countBubble {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      background: var(--black);
      color: var(--white);
      text-align: center;
      line-height: 20px;
      border-radius: 8px;
      padding: 0;
      margin: 2px;
      font-size: 13px;
      font-weight: bold;
      transition: all ease 0.3s;

      &.changed {
        transform: scale(1.3);
      }
    }
  }

  &.white {
    background: var(--gradientText);
    color: var(--primary); // TODO: Choose darker color

    @media all and (max-width: 700px) {
      display: none;
    }
  }
}

@keyframes fadein {
  100% {
    opacity: 1;
    transform: none;
  }
}
